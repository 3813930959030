/* Start of cart CSS */

   @keyframes shake {
      10%, 90% {
        transform: translate3d(-1px, 0, 0);
      }
      
      20%, 80% {
        transform: translate3d(2px, 0, 0);
      }
    
      30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
      }
    
      40%, 60% {
        transform: translate3d(4px, 0, 0);
      }
    }
    
.c-cart {

    .c-cart__description {
        margin: 4px 0;
        font-weight: bold;
        font-size:15px;
    }
    
    .c_cart__ordertotal {
        font-size:22px;
        font-weight: bold;
    }
    
    .c_cart__tableheading {
        background-color: @gray-lighter;
    }
    
    .c-cart__listings .c-cart__column {
        vertical-align: middle;
        border-top: 1px solid @grey-light-custom;
        border-bottom: none; //added to override froala
        border-left: none; //added to override froala
        border-right: none; //added to override froala
    }
    
    .c-cart__cartremove {
            font-size:20px;
            
            > .is-animate {
            animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000px;
        }
    
    }
    
    .c-cart__quantity-selector-form {
        display: inline;
    }

    
}
.c-offercode {
    .c-offercode__item {
         padding: 0 8px;
    }
    
    .c-offercode__input {
        max-width: 200px;
        }
    .c-offercode__btn-submit{
        margin-bottom: 0; //override Rajinders class
    }
}
@media (min-width: 768px) {
   .c-offercode {    
    .c-offercode__input {
    margin: 0 10px;
        }
    }
}

.l-section-headings {
       border-bottom: 1px solid @gray;
}

.btn-default--inverse {
    .button-variant(@btn-default-border; @default-background-colour; @btn-default-border);
}

.c-purchase {
    text-align: left;
}

.c-purchase {
    &#itemAddedToCart {
         .c-purchase__type {
            min-height: unset;
         }
        }
    .c-purchase__type {
        width: 50%;
        min-height: 210px;
        float:left;
        text-align:center;
        position:relative;
        
        &:nth-child(odd):not(:last-child) {
            border-right: 1px solid @gray;
        }
        
        &:nth-child(n+3) {
            border-top: 1px solid @gray;
        }
        
        &:last-child:not(:nth-child(even)) {
            width:100%;
            
        }
    }
    .c-purchase__description {
        text-align:center;
        margin-top: 10px;
        
    }
    .c-purchase__form {
            display:flex;
            flex-direction: column;
            min-width: 220px;
            min-height: 180px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            padding:10px;
        }
     .c-purchase__doi {
            display: block;
        }
    .c-purchase__type-add-to-cart {
        display: block;
        margin: 0 auto;
    }
    
    .c-purchase__type-price-label {
        float:left;
        width:50%;
    }
    
    .c-purchase__type-price-amount {
        float:right;
        width:50%;
        font-weight:bold;
    }
    
    .c-purchase__type-description {
        flex-grow: 1;
    }
    
}

@media (max-width: 768px) {
   .c-cart .c-cart__description {
        font-size: 14px;
    }
  .c-purchase .c-purchase__type {   
    width:100%;
    display: flex;
    flex-direction: column;
    min-height: auto;
    border-right: none !important;
    border-top: 1px solid @gray;
    align-content: stretch;
        .c-purchase__form {
            position:static;
            left: auto;
            top: auto;
            transform: none;
            margin:5px;
            min-width: auto;
            min-height:auto;
            display:block;
            }
            
        .c-purchase__type-price-label {
            display:none;
        }
        
        .c-purchase__type-description {
            float:left;
            width:50%;
            flex-grow: 0;
            &.c-purchase__type-description--price_display_on_button_modifier {
                width:100%; 
            }
        }
        .c-purchase__type-price {
            float: right;
            width: 50%;
            
        }
        .c-purchase__type-price-amount {
            float: none;
            width: 100%;
        }
        
        .c-purchase__type-add-to-cart {
            clear:both;
            width: 80%;
            min-width: 118px;
        }
        
        .c-purchase__cancelbutton {
            width: auto;
        }
        
    }


}

.cart-button {
    position: relative;
    .cart-button__item-count {
      padding-left: 4px;
      padding-right: 4px;
      font-size: 12px;
      border-radius: 50%;
      background-color: @search-highlighted-text;
      position: absolute;
      top: 15%;
      right: 18%;
      height: 14px;
      line-height: 14px;
      text-align: center;
      font-weight: bold;
    }
}

.cart-button-desktop {
    position: relative;
    .cart-button-desktop__item-count {
      font-size: 12px;
      border-radius: 50%;
      background-color: @search-highlighted-text;
      color: @brand-secondary;
      position: absolute;
      top: 10%;
      left: 50%;
      height: 14px;
      line-height: 14px;
      text-align: center;
      font-weight: bold;
      padding-left: 4px;
      padding-right: 4px;
    }
}


.c-address-billing__submit-button-container {
    margin-bottom: 20px;
}

.c-shipping-address {
    display: none;
}

.c-paymentagencies__submit-button {
    margin-right: 10px;
} 


.img-responsive--display-inline-block {
    display: inline-block;
}

/* End of cart css */