
// bootstrap variables overridden


@link-color:    #4d4d4d;

@midnight-black: #000;

@light-blue: #fafdff;

@navbar-padding-vertical: 5px;



@font-size-base: 14px;
@font-size-smaller: @font-size-base * 0.7142857142857143; // ~10px; 
@font-size-small:    @font-size-base * 0.8571428571428571; //~12px 
@font-size-larger:	@font-size-base * 1.142857142857143; // ~16px
@font-size-h1:           ceil((@font-size-base * 1.928)); // ~27px; 
@font-size-h2:           ceil((@font-size-base * 1.785)); // ~25px;
@font-size-h3:         	 ceil((@font-size-base * 1.357)); // ~19px;
         

@font-size-abstract: @font-size-base;
@font-size-fulltext: @font-size-larger;
@font-line-height-fulltext: 2;
@font-size-content-item-title: ceil((@font-size-base * 1.357142857142857)); //19px;
@font-line-height-content-item-title: ceil((@font-size-base * 1.714285714285714)); //24px;




@main-content-box-padding: 0 15px;
@font-size-huge: ceil((@font-size-base * 1.75)); // ~ 28px; (used for SGM banner)



@headings-font-weight:    700;
@font-bold:    700;


@screen-xxs-max:             (@screen-xs-min - 1);
//@screen-sm:                  820px; /* needed to accommodate mast head navigation */

//open sans is linked via google fonts from template.jsp
@font-family-sans-serif:   'Roboto', sans-serif;
@headings-font-family: 'Roboto Condensed';

@screen-md: 845px; //becomes @screen-md-min after pre-pass -1
@max-full-width-search-box: 578px;
@max-width-ultra-compact-ui: 405px;
@max-width-ultra-ultra-compact-ui: 380px;

/* Point at which the navbar becomes uncollapsed. Changes as part of bug 61339. */
@grid-float-breakpoint:     @screen-md-min;
/** Point at which the navbar begins collapsing.*/
@grid-float-breakpoint-max: (@grid-float-breakpoint - 1);

@navbar-default-bg: #fff;
//@navbar-default-brand-hover-bg: @navbar-default-bg;


@mobile-menu-opacity: 100%; //no transparency

@brand-secondary: #333; 
@brand-primary: @brand-secondary;
@brand-secondary-hsl: hsl(0, 0.0%, 12.9%);
@brand-success: #008461;
@brand-tertiary: #069edb; /*008461 //custom var*/

@open-dropdown: #1051c7;
@state-danger-text: #ff0000;

@footer-bkg-color: @brand-primary;
@footer-default-text-color: @default-background-colour;
@footer-default-link-color: @footer-default-text-color;
@footer-back-to-top-link-hover-bkg: #000;
@footer-back-to-top-link-bkg: @default-background-colour;

@dropdown-link-color: @link-color;
@dropdown-link-hover-bg: #2e6da4;
@dropdown-link-hover-color: @default-background-colour;
@dropdown-select-text-line-height: @line-height-base; //bootstrap var

@mega-menu-hover-bg: @default-background-colour;
@mega-menu-hover-color: @link-color;
@mega-menu-border-color: @brand-primary;
@mega-menu-link-color: @link-color;
@mega-menu-active-link: @link-color;

@hamburger-main-menu-hover-color: @dropdown-link-hover-bg;
@nav-pills-active-link-hover-color: #000000;
@nav-pills-active-link-hover-bg: #F2F2F2;

@navbar-default-link-color: @dropdown-link-color;
@navbar-default-link-hover-color: @dropdown-link-hover-bg; 
@navbar-default-link-hover-bg: @default-background-colour;
@navbar-default-link-active-color: @dropdown-link-hover-bg; 
@navbar-default-link-active-bg: @default-background-colour;

@dropdown-default-expanded-button-color: @dropdown-link-hover-bg;

@inline-search_btn-colour: #009edb;
@inline-search_btn-text-colour: @default-background-colour;

//lets convert this into base branding speak see:
//https://publishingtechnology.atlassian.net/wiki/display/SABINET/Sabinet+Branding?preview=/30081132/30081131/Sabinet_Brand_Manual_11.pdf
@grey1: @gray-light;
@grey2: @gray;
@grey3: @gray-dark;

@text-color: @link-color;

@masthead-icon-link-menu-colour: @navbar-default-link-color;
@hamburger-main-menu-link-color: @navbar-default-link-color;


@panel-default-text: @text-color;

@brand-default: #009edb;

// when commented out the @btn-primary-bg and @btn-primary-border 
// will default to the primary color
@btn-primary-color: white;
@btn-primary-bg: @brand-default;
@btn-primary-border: @btn-primary-bg; 


//the success color is known as the secoundry colour in our docs:

@tabs-icon-fonticon-border-color: @brand-success;
@tabs-icon-fonticon-color: @brand-success;

/*pagination*/
@pagination-hover-bg: @brand-primary;
@pagination-hover-color :@navbar-default-bg;
@pagination-active-bg: @link-color;

/*navbar-default active color*/

@navbar-default-toggle-hover-bg:  transparent;




//@btn-font-weight:                bold;
@btn-default-color:              @default-background-colour;
@btn-default-bg:                 @brand-default;
@btn-default-border:             @btn-default-bg;

@btn-admin-color:              @brand-primary;
@btn-admin-bg:                 @default-background-colour;

@table-border-color:           @gray;


@light-bg-color: #f5f5f5;
@dborder-color: #dddddd;




@list-group-bg:                 transparent;
@zindex-dropdown:          1100; /* fix for bug 62182 */

// custom variables
@search-highlighted-text:    #ff0;

@platform-img-path: "/images/instance/";

@banner-filename-common: "_banner.png"; // ~ e.g "micro_banner.gif"
@banner-filename-common-tablet: "_banner_tablet.png"; 
@banner-filename-common-mobile: "_banner_mobile.png"; 



@breadcrumb-bg: transparent;
@breadcrumb-padding-horizontal: 0;

// X-Small
.pagination-xs {
  .pagination-size(1px; 5px; 14px; 0px); 
 // @padding-small-vertical; @padding-small-horizontal; @font-size-small; @border-radius-small);
}

@pager-border-radius: 0;

@mobile-menu-position: absolute;

@share-icon-color: @link-color;
@share-icon-hover-color: @default-background-colour;
@share-icon-hover-bg: @dropdown-link-hover-bg;
@share-icon-hover-border-color: @share-icon-hover-bg;


@home-page-banner-font-weight: 700;

@home-page-hero-search-box-width: 600px;

//temp lets fix for UN and then we can test on other sites later
#article .right-title-box {
    display: flex;
    flex-direction: column;
    min-width: 109px;
    .download-pdf {
        padding:0;
    }
    .ft-download-content__form button {
        margin-left:0;
    }
    
}
 











